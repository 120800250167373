<template>
    <div class="page-start">
        <div class="content-login">
            <div class="box-login">
                <div v-if="!route.query.unknownUser">
                    <h2>Log ind på Skoleadmin</h2>
                    <p class="mb-4">(Kun for skoler/medarbejdere)</p>
                    <router-link  to="/admin" class="btn-login">Log ind med MitID</router-link>
                </div>
                <div v-if="route.query.unknownUser">
                    <h2 class="mb-4">Vi kunne ikke logge dig ind</h2>
                    <p>
                        Vi kunne ikke logge dig ind fordi din bruger ikke er oprettet i systemet. <br /><br />
                        Denne side er kun beregnet til systembrugere af Skoleadmin.
                    </p>
                </div>
                
            </div>
        </div>
        <div class="content-branding">
            <img src="../assets/skoleadmin.svg" alt="Skoleadmin log ind" />
            <div class="text-left p-5" style="max-width:450px;margin:0 auto;">
                <h2>Problemer med at logge på eller ny på Skoleadmin?</h2>
                <p class="mt-5 mb-1">Kontakt os på: </p>
                <p class="mb-2"><a href="mailto:support@skoleadmin.dk">support@skoleadmin.dk</a></p>
                <p><a href="tel:+4588441000">+45 88 44 1000</a></p>
                <div class="align-items-end flex mb-2 mt-6">
                    <span style="display: inline-block;background: #39bc6c;width: 20px;height: 20px;border-radius: 50%;margin-right: 0.5rem;"></span>Der er normal drift på alle systemer
                </div>
               
            </div>
            
        </div>
    </div>

</template>


<script setup>    
    import { useRoute } from 'vue-router';    
    const route = useRoute();
</script>
<style scoped>
    .page-start {
        background-color: #f4f4ff;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
    }

        .page-start img {
            max-width: 100%;
            width: 120px;
            margin: 0 auto 2rem;
        }




    .content-login {
        width: 100%;
        display: flex;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;
    }

    .box-login {
        max-width: 500px;
        width: 100%;
        padding: 2rem;
    }

    .content-branding {
        background: #4849a1;
        width: 100%;
        color: white;
        text-align: center;
        padding: 4rem 2rem;
    }

    .btn-login {
        display: inline-block;
        background: #004bb5;
        color: white;
        padding: 1rem 2rem;
        text-decoration: none;
        border-radius: 10px;
        font-weight: 500;
    }

    a {
        color: white;
        text-decoration: none;
        font-weight: 500;
        font-size: 20px;
    }

    @media (min-width: 768px) {
        .page-start {
            flex-direction: row;
        }

            .page-start img {
                width: 180px;
                margin: 6vh auto;
            }

        .content-login {
            width: 60%;
        }

        .box-login {
            min-height: 370px;
        }

        .content-branding {
            width: 40%;
            padding: 1.5rem;
        }
    }
</style>
<template>
    <div @click="fn.open">
        <slot>
            <Button label="Send dokument" icon="pi pi-envelope text-2xl" class="p-button-secondary p-100 py-3" style="font-size:1.2rem" />
        </slot>
    </div>
    <Sidebar v-model:visible="vm.showSidebar" position="right" class="p-sidebar-md document-sender">
        <div class="flex flex-column h-full">
            <h4>1) Hvilken skabelon vil du bruge?</h4>
            <Listbox v-model="vm.selectedDocumentDto"
                     @change="vm.onlyShowStudentsWithoutSelectedDocument = false"
                     :options="vm.documents"
                     :filter="true"
                     optionLabel="name"
                     listStyle="max-height:220px"
                     filterPlaceholder="Søg efter skabelon"
                     emptyFilterMessage="Ingen skabeloner matcher din søgning"
                     emptyMessage="Ingen skabeloner fundet. Har du husket at udgive din skabelon?"
                     class="p-100">
                <template #option="slotProps">
                    <div class="align-items-center flex">
                        <i v-if="slotProps.option.type === 1" class="pi pi-fw pi-dollar"></i>
                        <i v-else-if="slotProps.option.type === 2" class="pi pi-fw pi-file-pdf"></i>
                        <i v-else class="pi pi-fw pi-file"></i>
                        <div class="ml-2">{{slotProps.option.name}}</div>
                    </div>
                </template>
            </Listbox>
            <h4 class="pt-5">2) Hvem vil du sende til?</h4>
            <div class="field-checkbox" v-if="vm.selectedDocumentDto">
                <Checkbox inputId="force-remind" v-model="vm.onlyShowStudentsWithoutSelectedDocument" :binary="true" />
                <label for="force-remind">Vis kun elever, der ikke allerede har modtaget dokumentet</label>
            </div>
            <div class="relative">
                <div class="mb-2 text-sm flex align-items-center p-tree-filter-help-text">Klik på <i class="pi pi-chevron-right mx-2"></i> for at se elever på holdet</div>
                <Tree :value="vm.memberGroups" filterPlaceholder="Søg efter gruppenavn, elevnavn, elevnr ..." :filter="true" selectionMode="checkbox" v-model:selectionKeys="vm.selectedKeys"></Tree>
                <div v-if="selectedMemberIds && selectedMemberIds.length > 0" class="member-counter">
                    <span v-if="selectedMemberIds.length === 1">1 elev valgt</span>
                    <span v-else>{{selectedMemberIds.length}} elever valgt</span>
                </div>
            </div>
            <h4 class="pt-5">3) Hvem skal modtage?</h4>
            <div class="box pb-1">
                <div class="field-radiobutton">
                    <RadioButton inputId="send-to-all" name="send-to" value="0" v-model="vm.sendTo" />
                    <label for="send-to-all">Alle (elever og forældre/værger)</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton inputId="only-non-guardians" name="send-to" value="1" v-model="vm.sendTo" />
                    <label for="only-non-guardians">Kun elever</label>
                </div>
                <div class="field-radiobutton">
                    <RadioButton inputId="only-guardians" name="send-to" value="2" v-model="vm.sendTo" />
                    <label for="only-guardians">Kun forældre/værger</label>
                </div>
            </div>
            <div class="align-self-end mt-auto sticky bottom-spacing">
                <Button label="Send" class="p-button-success p-button-lg mt-4" @click="fn.send" />
            </div>
            <div v-if="vm.sending" class="overlay overlay-submitting">
                <ProgressSpinner />
            </div>
        </div>
    </Sidebar>
    <ConfirmDialog group="confirmSend" :style="{'max-width':'750px',width: '100%', margin:'0.5rem'}">
        <div>
            <div v-html="vm.selectedDocumentConfirmSendInfo"></div>
            <div v-if="vm.selectedDocument.autoRemindAfter">
                <div class="flex align-items-center mt-4">
                    <i class="pi pi-bell" style="font-size: 26px;width: 3rem;"></i>
                    <div>
                        <div class="secondary-label">Påmind efter</div>
                        <div class="flex align-items-center">
                            <InputNumber class="p-inputtext-sm mt-1 mr-2" :inputStyle="{'width' : '45px'}" :useGrouping="false" v-model="vm.selectedDocument.autoRemindAfter" @input="vm.autoRemindChanged = true" />
                            <strong style="font-size: 18px;">dag(e)</strong>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex align-items-center mt-4 mb-2">
                <i class="pi pi-envelope" style="font-size: 26px;width: 3rem;"></i>
                <div class="align-items-center flex flex-1 justify-content-between">
                    <div class="secondary-label">Mailbesked</div>
                    <div class="field-checkbox mb-0 ml-2">
                        <Checkbox inputId="remember-email-text" v-model="vm.selectedDocument.emailTextRemember" :binary="true" />
                        <label for="remember-email-text">Husk besked til næste gang?</label>
                    </div>
                </div>
            </div>
            <div style="height:300px" class="ml-6">
                <editor :init="{height: 300,license_key: 'gpl',paste_preprocess: handlePastePreprocess,menubar: false,default_link_target: '_blank', quickbars_image_toolbar: false, quickbars_insert_toolbar: false}"
                        plugins="link lists quickbars"
                        toolbar="bold underline strikethrough removeformat | link"
                        v-model="vm.selectedDocument.emailText"
                        @change="vm.emailTextChanged = true" />
            </div>
            <small class="block mt-3 ml-6">
                Flettefelter, der kan bruges i mailbeskeden:<br />
                <strong>{elevnavn}</strong> = Indsætter elevens navn<br />
                <strong>{elevefternavn}</strong> = Indsætter elevens efternavn.<br />
                <strong>{elevadresse}</strong> = Indsætter elevens adresse, hvis udfyldt.<br />
                <strong>{elevnummer}</strong> = Indsætter elevens nummer<br />
                <strong>{klassetrin}</strong> = Indsætter elevens klassetrin, også kendt som "Nuværende klasse" i KOMiT.<br /><br />
                <span>EKS: <em>Kære {elevnavn} og forældre</em></span>
            </small>
        </div>
    </ConfirmDialog>
    <ConfirmDialog group="confirmSendToMany" :style="{'max-width':'530px',width: '100%', margin:'0.5rem'}" :pt="{ footer : { class:  'text-center' }}">
        <i class="absolute align-items-center flex justify-content-center text-white pi pi-envelope" style="font-size: 2.6rem; border-radius: 50%; width: 110px; height: 110px; top: -60px; background: #ff4081; left: 50%; transform: translateX(-50%);"></i>
        <div class="text-center mb-4">
            <div>Du er ved at sende til</div>
            <div class="mb-1 mt-2 text-7xl font-bold" style="color: #4849a1; font-family: Figtree, sans-serif; ">{{selectedMemberIds.length}}</div>
            <div v-if="vm.sendTo === '1'">elever</div>
            <div v-else-if="vm.sendTo === '2'">elevers forældre/værger</div>
            <div v-else>elever og deres forældre/værger</div>
        </div>
    </ConfirmDialog>
</template>


<script>
    import { computed, defineComponent, inject, watch, reactive, ref } from 'vue';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from "primevue/useconfirm";
    import Editor from '@tinymce/tinymce-vue';
    import { handlePastePreprocess } from '../composables/paste-preprocess';
    import api from '../composables/api';
    import gqlBuilder from '../composables/gql-builder';
    //import _ from 'lodash';

    export default defineComponent({
        name: 'DocumentSender',
        props: {

        },
        emits: ['onCreated'],
        components: {
            'editor': Editor
        },
        setup() {
            const store = inject('store');
            const confirm = useConfirm();
            const toast = useToast();
            const memberGroupIdsString = ref('');
            let copyOfMemberGroups = ref();
            const vm = reactive({
                showSidebar: false,
                showConfirmSendSidebar: false,
                documents: null,
                selectedDocumentDto: ref(),
                selectedDocument: {},
                membergroups: null,
                selectedKeys: null,
                sendTo: '0',
                selectedDocumentConfirmSendInfo: '',
                sending: false,
                emailTextChanged: false,
                autoRemindChanged: false

            });

            const selectedMemberIds = computed(() => {
                const ids = [];
                if (vm.memberGroups) {
                    const allowedKeys = extractKey(vm.memberGroups);

                    for (const property in vm.selectedKeys) {
                        var obj = vm.selectedKeys[property];
                        if (obj.checked && memberGroupIdsString.value.indexOf(property) === -1 && allowedKeys.indexOf(property) !== -1) {
                            ids.push({ id: property });
                        }
                    }
                }

                return ids;
            })

            const init = () => {
                // RESET
                vm.selectedDocumentDto = ref();
                vm.selectedKeys = null;

                // GET PUBLISHED DOCUMENTS
                var gql = gqlBuilder.documentTemplates.get(store.auth.customerId);
                // TODO: This is a quickfix because the gql builder package used doesn't support filters
                // Need to find a different one
                gql.query = gql.query.replace('customerId: $customerId', 'customerId: $customerId, where: { and: [ {state: {eq:1}}, {isActive: {eq:true}}]}, order: {updated:DESC}')

                api.query(gql).then((response) => {
                    if (api.hasData(response)) {
                        vm.documents = api.getData(response);

                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                    }
                });

                // GET USERGROUPS WITH USERS
                api.query(gqlBuilder.memberGroups.getWithMembers(store.auth.customerId)).then((response) => {
                    if (api.hasData(response)) {
                        const data = api.getData(response);
                        const memberGroups = [];
                        for (var i = 0, l = data.length; i < l; i++) {
                            var children = [];
                            var group = data[i];
                            if (group.members && group.members.length && group.isActive && group.type !== 'DEFAULT') {
                                for (var j = 0, ml = group.members.length; j < ml; j++) {
                                    var child = data[i].members[j];
                                    if (child.isActive) {
                                        children.push({
                                            key: child.id,
                                            label: `${child.externalId ? '[' + child.externalId + '] ' : ''} ${child.firstname} ${child.lastname} (${child.email})`,
                                            icon: 'pi pi-fw pi-user',
                                            type: 'member',
                                            ownedDocuments: child.ownedDocuments.map(document => document.documentTemplate.id).filter((id, index, self) => self.indexOf(id) === index).join(', ')
                                        });
                                    }
                                }
                                memberGroups.push({
                                    key: group.id,
                                    label: `${group.name}`,
                                    icon: 'pi pi-fw pi-users',
                                    type: 'memberGroup',
                                    children: children
                                });
                                memberGroupIdsString.value = memberGroupIdsString.value + `|${group.id}|`;

                            }
                        }

                        vm.memberGroups = memberGroups;
                        copyOfMemberGroups = JSON.parse(JSON.stringify(memberGroups));

                    } else {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: response.errors[0].message });
                    }
                });
            };

            const fn = {
                open() {
                    init();
                    vm.showSidebar = true;
                },
                send() {
                    if (vm.selectedDocumentDto && selectedMemberIds.value.length) {
                        vm.emailTextChanged = false;
                        vm.autoRemindChanged = false;

                        // HTML for confirm data
                        var message = '<div class="align-items-center flex"><i class="pi pi-file" style="font-size: 26px;width: 3rem;"></i><div><div class="secondary-label">Dokument</div><strong style="font-size: 18px;">' + vm.selectedDocumentDto.name + '</strong></div></div>';

                        message += '<div class="align-items-center flex mt-4"><i class="pi pi-users" style="font-size: 26px;width: 3rem;"></i><div><div class="secondary-label">Modtager(e)</div><strong class="align-items-center flex" style="font-size: 18px;">';
                        message += '<span class="recipient-count mr-1 px-3">' + selectedMemberIds.value.length + '</span>';
                        if (vm.sendTo === '0') {
                            message += (selectedMemberIds.value.length === 1 ? ' elev' : ' elever') + ' og deres forældre/værger';
                        } else if (vm.sendTo === '1') {
                            message += (selectedMemberIds.value.length === 1 ? ' elev' : ' elever');

                        } else if (vm.sendTo === '2') {
                            message += (selectedMemberIds.value.length === 1 ? ' elevs' : ' elevers') + ' forældre/værger';
                        }
                        message += '</strong></div></div>';

                        vm.selectedDocumentConfirmSendInfo = message;
                        vm.selectedDocument = {};

                        api.query(gqlBuilder.documentTemplates.getById(store.auth.customerId, vm.selectedDocumentDto.id)).then((response) => {
                            if (api.hasData(response)) {
                                vm.selectedDocument = api.getData(response);
                            }
                        });


                        if (selectedMemberIds.value.length > 9) {
                            confirm.require({
                                group: 'confirmSendToMany',
                                header: ' ',
                                acceptLabel: 'Ja, videre til bekræftelse',
                                acceptClass: 'p-button-success p-button-lg',
                                rejectLabel: 'Nej, fortryd',
                                rejectClass: 'p-button-link p-button-lg',
                                accept: () => {
                                    showConfirmSendModal();
                                }
                            });
                        } else {
                            showConfirmSendModal();
                        }

                    } else {
                        if (!vm.selectedDocumentDto) {
                            toast.add({ severity: 'error', summary: 'Ingen dokument valgt', detail: 'Vælg venligst et dokument, der skal afsendes', life: 10000 });
                        } else if (!selectedMemberIds.value.length) {
                            toast.add({ severity: 'error', summary: 'Ingen modtager valgt', detail: 'Vælg venligst minimum en modtager, der skal sendes til', life: 10000 });
                        }
                    }
                }
            }

            function showConfirmSendModal() {
                confirm.require({
                    group: 'confirmSend',
                    header: 'Klar til afsendelse?',
                    acceptLabel: 'Ja, send',
                    acceptClass: 'p-button-success p-button-lg',
                    rejectLabel: 'Nej, luk',
                    rejectClass: 'p-button-link p-button-lg',
                    accept: () => {
                        vm.sending = true;

                        var documentSettings = {};
                        if (vm.emailTextChanged || vm.autoRemindChanged) {
                            documentSettings.emailText = vm.selectedDocument.emailText;
                            documentSettings.autoRemindAfter = vm.selectedDocument.autoRemindAfter;
                            documentSettings.isDefaultDocumentTemplateValues = vm.selectedDocument.emailTextRemember || false;
                        }

                        api.query(gqlBuilder.documentTemplates.send(store.auth.customerId, vm.selectedDocument.id, selectedMemberIds.value, vm.sendTo === '2', vm.sendTo === '1', documentSettings)).then((response) => {
                            vm.showSidebar = false;
                            if (api.hasData(response)) {
                                vm.selectedDocumentDto = null;
                                vm.selectedKeys = null;
                                toast.add({ severity: 'success', summary: 'Dokument(er) afsendt', detail: 'Der kan gå op til 5 minutter før man kan se de afsendte dokumenter i systemet', life: 8000 });
                            }
                            vm.sending = false;
                        });
                    }
                });
            }

            function extractKey(children) {
                return children.map(child => {
                    if (child.children && child.children.length) {
                        return extractKey(child.children);
                    }
                    return child.key;
                }).flat();
            }

            watch(() => vm.onlyShowStudentsWithoutSelectedDocument, (value) => {
                if (value) {
                    vm.memberGroups.forEach(function (obj) {
                        obj.children = obj.children.filter(function (child) {
                            return child.ownedDocuments.indexOf(vm.selectedDocumentDto.id) === -1;
                        });
                    });

                } else {
                    vm.memberGroups = JSON.parse(JSON.stringify(copyOfMemberGroups));
                }
            });

            return { vm, fn, selectedMemberIds, handlePastePreprocess };
        }
    });
</script>


<style lang="scss">
    .document-sender {
        .p-tree-filter-container {
            margin-bottom: 3rem;
        }

        .p-tree-filter-help-text {
            position: absolute;
            top: 5.4rem;
            left: 1.5rem;
        }

        .p-tree-wrapper {
            margin-bottom: 1rem;
        }

        .member-counter {
            position: absolute;
            bottom: 1rem;
            right: 1.5rem;
            font-size: 14px;
        }
    }

    .recipient-count {
        background: #ffd500;
        font-weight: bold;
        font-size: 30px;
        border-radius: 5px;
        text-align: center;
    }
</style>
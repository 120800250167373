<template>
    <div class="page-admin-log">
        <page-header headline="Hændelseslog">
            <InputText v-model="auditLogFilters['global'].value" style="width:100%" placeholder="Søg efter hændelse, navn, dokument..." />
        </page-header>
        <div class="content-wrapper">
            <div class="main-content">
                <div v-if="vm.fetchingData" class="text-center">
                    <ProgressSpinner />
                </div>
                <div v-else>
                    <DataTable v-if="vm.auditLog && vm.auditLog.length"
                               :value="vm.auditLog"
                               :filters="auditLogFilters"
                               responsiveLayout="scroll"
                               :paginator="vm.auditLog.length > 50" :rows="50"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} hændelser"
                               sortField="created" :sortOrder="-1">
                        <Column class="column-state" header="Type" sortable field="type" headerStyle="width:120px">
                            <template #body="slotProps">
                                <Tag v-if="slotProps.data.type === 'MEMBER'" value="Kontakt" style="border-radius: 6px 0 0 6px;"></Tag>
                                <Tag v-else-if="slotProps.data.type === 'MEMBER_GROUP'" value="Gruppe" style="background: #313275; border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else-if="slotProps.data.type === 'MEMBER_GROUP_MEMBER'" value="Gruppe kontakt" style="background: #4849a1; border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else-if="slotProps.data.type === 'CONSENT'" value="Samtykke" style="background: #48a1a0; border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else-if="slotProps.data.type === 'DOCUMENT_TEMPLATE'" value="Skabelon" severity="info" style="border-radius: 6px 0 0 6px; "></Tag>
                                <Tag v-else value="Dokument" severity="success" style="border-radius: 6px 0 0 6px;"></Tag>
                                <pre>{{slotProps.data.type}}</pre>
                            </template>
                        </Column>
                        <Column class="column-state" header="Action" sortable field="action" headerStyle="width:120px">
                            <template #body="slotProps">
                                {{slotProps.data.action}}
                                <Tag v-if="slotProps.data.action === 'DELETE'" severity="danger" value="Slet" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'UPDATE'" severity="success" value="Opdatering" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'MAIL_SEND'" severity="info" value="Mail" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'MAIL_REMIND'" severity="info" value="Påmindelsesmail" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'SMS_SEND'" severity="info" value="SMS" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'SMS_REMIND'" severity="info" value="Påmindelses SMS" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'REMOVE_MEMBER'" severity="danger" value="Elev fjernet" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'IMPORT'" severity="success" value="Import" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'CONSENT_REJECTED'" severity="danger" value="Samtykke afvist" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'MOVE_TO_RECYCLE_BIN'" severity="warning" value="Papirkurv" class="border-noround"></Tag>
                                <Tag v-else-if="slotProps.data.action === 'RESTORED'" severity="success" value="Gendannet" class="border-noround"></Tag>
                                <Tag v-else severity="success" value="Opret" class="border-noround"></Tag>
                            </template>
                        </Column>
                        <Column field="role" header="Hvem"></Column>
                        <Column field="message" header="Besked"></Column>
                        <Column field="created" header="Oprettet" :sortable="true" bodyStyle="text-align:right">
                            <template #body="slotProps">
                                <span v-html="$filters.formatDateHtml(slotProps.data.created)"></span>
                            </template>
                        </Column>
                    </DataTable>
                    <Message severity="info" :closable="false" v-else>
                        Der har ikke været nogen hændelser de seneste 30 dage
                    </Message>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { inject, ref, reactive } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import api from '../../composables/api';
    import gqlBuilder from '../../composables/gql-builder';
    import { useToast } from 'primevue/usetoast';

    export default {
        setup() {
            const store = inject('store');
            const toast = useToast();
            const auditLogFilters = ref({
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            });
            const vm = reactive({
                auditLog: null,
                fetchingData: false
            });
            const fn = {};



            // INIT
            vm.fetchingData = true;
            api.query(gqlBuilder.auditLog.get(store.auth.customerId)).then((response) => {
                if (api.hasData(response)) {
                    vm.auditLog = api.getData(response);
                }
                vm.fetchingData = false;
            }).catch((error) => {
                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                vm.fetchingData = false;
            });

            return {
                vm, fn, auditLogFilters
            };
        }
    }
</script>

<style>
    .page-admin-settings .p-image img {
        max-width: 100%;
    }
</style>
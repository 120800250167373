<template>
    <div v-if="vm.initializing" style="margin: 100px auto 0" class="loader-wrapper">
        <ProgressSpinner />
    </div>
    <router-view v-else />
    <div v-if="vm.apiDown" class="text-center p-4 p-100">
        <div class="pt-5 pb-3">
            <a href="https://skoleadmin.dk">
                <img src="./assets/skoleadmin-text-purple.svg" alt="skoleadmin.dk" style="max-width: 120px;" />
            </a>
        </div>
        <h2>Sitet er midlertidigt nede</h2>
        <p>Prøv igen om lidt</p>
    </div>
    <Toast />
    <ConfirmDialog></ConfirmDialog>
</template>
<script>
    import { inject, reactive } from 'vue';
    import api from './composables/api';
    import gqlBuilder from './composables/gql-builder';
    import { useRouter } from 'vue-router';

    export default {
        //provide() {
        //    return {
        //        message: computed(() => this.message)
        //    }
        //},
        setup() {
            const router = useRouter();
            const rootUrl = inject('rootUrl');
            const apiUrl = inject('apiUrl');
            const store = inject('store');
            const vm = reactive({
                initializing: false,
                apiDown: false
            });

            router.beforeEach((to, from, next) => {
                if (to.meta.requiresSystemUserAuth || to.meta.requiresMemberAuth) {
                    api.get(apiUrl + 'authentication/IsAuthenticated').then((response) => {

                        if (!response.isAuthenticated) {
                            let returnUrl = encodeURIComponent(rootUrl + to.path);
                            if (to.meta.requiresSystemUserAuth) {
                                window.location.href = apiUrl + 'authentication/authenticate?returnUrl=' + returnUrl;
                            } else {
                                router.push({
                                    name: 'Login',
                                    query: { returnUrl: returnUrl }
                                });
                            }
                        } else if (response.unknownUser) {                            
                            let returnUrl = encodeURIComponent(rootUrl + '?unknownUser=true');
                            window.location.href = apiUrl + 'authentication/SignOut?returnUrl=' + returnUrl;                            
                        }
                        else {
                            if (response.customerIds && response.customerIds.length > 0) {
                                store.setCustomerId(response.customerIds[0]);
                            }

                            if (to.meta.requiresSystemUserAuth) {
                                if (response.systemUserIds[0]) {
                                    api.query(gqlBuilder.systemUsers.getById(response.systemUserIds[0])).then((userResponse) => {
                                        if (api.hasData(userResponse)) {
                                            store.setAuthenticated(response.isAuthenticated);
                                            store.setModules(response.modules);
                                            store.setCurrentSystemUserId(response.systemUserIds[0]);
                                            store.setCurrentUser(api.getData(userResponse));
                                        }
                                    });
                                } else {
                                    router.push({
                                        name: 'Start'
                                    });
                                }
                            } else if (to.meta.requiresMemberAuth) {
                                if (response.endUserId) {

                                    api.query(gqlBuilder.endUsers.getById(response.endUserId)).then((userResponse) => {
                                        if (api.hasData(userResponse)) {
                                            store.setAuthenticated(response.isAuthenticated);
                                            store.setCurrentSystemUserId('');
                                            store.setCurrentUser(api.getData(userResponse));
                                        }
                                    });
                                } else {
                                    router.push({
                                        name: 'Start'
                                    });
                                }
                            }

                            next();
                            return;
                        }

                    }).catch(() => {
                        vm.apiDown = true;
                    });

                } else {
                    next();
                }
            });


            return {
                vm
            }
        }
    }
</script>
<style lang="scss">
    @import './styling/main';
</style>

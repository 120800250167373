<template>
    <Button label="Opret nyt samtykke" icon="pi pi-plus" class="p-button-success mr-2" @click="fn.showCreateSidebar" />
    <Sidebar v-model:visible="vm.slideInCreateConsent" position="right" class="p-sidebar-md">
        <h3 class="pb-3" v-if="vm.isEdit">Opdatér samtykke</h3>
        <h3 class="pb-3" v-else>Opret nyt samtykke</h3>
        <form @submit.prevent="fn.createOrUpdate(v$)" class="p-fluid" novalidate>
            <div class="grid">
                <div class="col-12">
                    <span class="p-float-label">
                        <InputText id="inputname" type="text" class="p-100 p-inputtext-lg" v-model="data.name" :class="{'p-invalid':v$.name.$invalid && vm.submitted}" required />
                        <label for="inputname">Samtykke overskrift *</label>
                    </span>
                    <small v-if="(v$.name.$invalid && vm.submitted) || v$.name.$pending.$response" class="p-error">Navn skal udfyldes</small>
                </div>
                <div class="col-12">
                    <span class="p-float-label">
                        <Textarea id="inputdescription" type="text" class="p-100 p-inputtext-lg" v-model="data.description" :autoResize="true" rows="5" cols="30" :class="{'p-invalid':v$.description.$invalid && vm.submitted}" required />
                        <label for="inputdescription">Beskrivelse *</label>
                    </span>
                    <small v-if="(v$.description.$invalid && vm.submitted) || v$.description.$pending.$response" class="p-error">Beskrivelse skal udfyldes</small>
                </div>              
            </div>
            <div class="text-right mt-3 sticky bottom-spacing">
                <Button type="submit" :label="vm.buttonText" class="p-button-success p-button-lg mt-2" style="width:auto;" />
            </div>
        </form>
        <div class="box mt-5">
            <h4>Om samtykker</h4>
            <p><small>Husk at et samtykke skal være frivilligt, utvetydigt, specifikt og informeret. Personen, der skal afgive samtykke, skal som minimum vide, hvem den dataansvarlige er, formålet med den påtænkte behandling, hvilke oplysninger, der behandles samt retten til at trække samtykket tilbage. Formuleringen skal være så specifik og utvetydig som muligt, og der skal reelt være tale om frit valg.</small></p>
            <p><small>Her følger et eksempel på samtykke.</small></p>
            <p>
                <em>
                    <small>
                        <strong>Samtykke til brug af foto</strong><br />
                        Sommerby Efterskole (CVR 12345678) vil gerne have mulighed for at fotografere dit barn mens det er på efterskolen, eller når vi er på rejser/ture. Billederne vil kunne blive brugt på vores hjemmeside (www.sommerbyefterskole.dk), vores intranet og til trykte publikationer/markedsføringsmateriale om efterskolen. Vi deler ikke billederne hverken via mail eller på sociale medier. Sammen med et billede, registrerer vi tidspunkt og navnene på de personer, der er på billedet. Dette gør vi, så vi nemt kan slette billederne, såfremt du tilbagetrækker dit samtykke. Vi sletter billeder senest efter 3 år. Du og dit barn vil ikke blive stillet ringere, såfremt I ikke ønsker at afgive samtykke.
                    </small>
                </em>
            </p>
        </div>
    </Sidebar>
</template>


<script>
    import { defineComponent, inject, reactive, computed } from 'vue';
    import { required } from "@vuelidate/validators";
    import { useVuelidate } from "@vuelidate/core";
    import api from '../composables/api';
    import { useToast } from 'primevue/usetoast';

    export default defineComponent({
        name: 'ConsentCreator',
        props: {
            onSaved: Function,
            input: Object,
            identifier: Number
        },
        emits: ['onSaved'],

        setup(props, { emit }) {
            const toast = useToast();
            const apiUrl = inject('apiUrl');
            const vm = reactive({
                slideInCreateConsent: false,
                submitted: false,
                buttonText: 'Opret',
                isEdit: false
            });
            let data = reactive({
                name: '',
                description: ''
            });
            const dataRules = computed(() => {
                return {
                    name: { required },
                    description: { required }                   
                }
            });

            let v$ = useVuelidate(dataRules, data);

            const fn = {
                createOrUpdate: (form) => {
                    vm.submitted = true;

                    if (form.$invalid) {
                        return;
                    }

                    // Update
                    if (data.id) {
                        const consentDto = { name: data.name, description: form.description.$model };
                        api.patch(apiUrl + 'consents/' + data.id, JSON.stringify(consentDto)).then((res) => {
                            if (res.status === 200) {
                                consentDto.id = data.id;
                                consentDto.updated = Date.now();
                                emit('onSaved', consentDto, vm.isEdit);
                                vm.slideInCreateConsent = false;

                                toast.add({ severity: 'success', summary: 'Samtykke opdateret', life: 3000 });
                            } else {
                                toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: 'Prøv igen' });
                            }

                        }).catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error });
                        });
                    }
                    // Create
                    else {
                        const consentDto = {
                            name: data.name,
                            description: form.description.$model                           
                        }

                        api.postJson(apiUrl + 'consents', consentDto).then((response) => {
                            emit('onSaved', api.getData(response), vm.isEdit);
                            vm.slideInCreateConsent = false;
                            toast.add({ severity: 'success', summary: 'Samtykke oprettet',  life: 3000 });

                            form.name.$model = '';
                            form.description.$model = '';
                            form.$reset();
                        }).catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                            vm.fetchingData = false;
                        });
                    }
                },
                edit: (consent) => {
                    initData(consent);
                    vm.buttonText = 'Opdatere';
                    vm.isEdit = true;
                    vm.submitted = false;
                    vm.slideInCreateConsent = true;
                },
                showCreateSidebar: () => {
                    initData();
                    vm.buttonText = 'Opret';
                    vm.submitted = false;
                    vm.slideInCreateConsent = true;
                }
            }

            function initData(consent) {
                consent = consent || {};
                data.id = consent.id || null;
                data.name = consent.name || '';
                data.description = consent.description || '';
                data.autoRenewal = consent.autoRenewal || null;
                data.canAcceptWithoutGuardian = consent.canAcceptWithoutGuardian || null;
                data.expiresAfterMonths = consent.expiresAfterMonths || null;

                vm.isEdit = false;
            }

            return { v$, vm, fn, data };
        }
    });
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>

<template>
    <div class="pincode-input-wrapper">
        <div v-for="(cell, index) in cells" :key="cell.key" class="input-group">
            <input :ref="setItemRef(index)"
                   v-model.trim="cell.value"
                   class="pincode-input"
                   type="tel"
                   @input="emit('update:modelValue', $event.target.value)"
                   @focus="focusedCellIdx = index"
                   @keydown.delete="onCellErase(index, $event)"
                   @keydown="onKeyDown(index, $event)"
                   @paste="onPaste"
                   autocomplete="off"
                   role="presentation" />
            <span v-if="(index + 1) % 3 === 0 && index + 1 < cells.length" class="space mx-3 text-5xl"></span>
        </div>
    </div>
</template>


<script setup>
    import { ref, computed, watch, onMounted, nextTick, defineProps, defineEmits } from 'vue';
    const props = defineProps({
        modelValue: {
            type: String,
            required: true
        },
        length: {
            type: Number,
            default: 4
        },
        autofocus: {
            type: Boolean,
            default: true
        }
    });

    const emit = defineEmits(['update:modelValue']);

    const CELL_REGEXP = '^\\d{1}$';
    const focusedCellIdx = ref(0);
    const cells = ref([]);
    const pinCodeComputed = computed(() => cells.value.reduce((pin, cell) => pin + cell.value, ''));

    // Watcher
    watch(() => props.modelValue, () => {
        onValueUpdated();
    });

    // Refs
    const itemRefs = [];
    const setItemRef = (index) => (el) => {
        if (el) {
            itemRefs[index] = el;
        }
    };

    // Functions
    const init = () => {
        for (let key = 0; key < props.length; key += 1) {
            setCellObject(key);
            setCellWatcher(key);
        }
    };

    const setCellObject = (key) => {
        cells.value[key] = { key, value: '' };
    };

    const setCellWatcher = (index) => {
        watch(() => cells.value[index].value, (newVal, oldVal) => onCellChanged(index, newVal, oldVal));
    };

    // Events
    const onValueUpdated = () => {
        if (props.modelValue.length !== props.length) {
            emit('update:modelValue', pinCodeComputed.value);
            return;
        }

        pinCodeComputed.value.split('').forEach((cell, idx) => {
            cells.value[idx].value = cell || '';
        });
    };

    const onCellChanged = (index, newVal) => {
        if (!isTheCellValid(newVal)) {
            cells.value[index].value = '';
            return;
        }

        focusCellByIndex(index + 1);
    };

    const onCellErase = (index, e) => {
        const isThisCellFilled = cells.value[index].value.length;

        if (!isThisCellFilled) {
            focusCellByIndex(index - 1);
            e.preventDefault();
        }
    };

    const onKeyDown = (index, e) => {
        switch (e.keyCode) {
            case 37:
                focusCellByIndex(index - 1);
                break;
            case 39:
                focusCellByIndex(index + 1);
                break;
            default:
                break;
        }
    };

    const onPaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text/plain').trim();
        for (let i = 0; i < props.length; i++) {
            if (i < pastedData.length && pastedData[i].match(CELL_REGEXP)) {
                cells.value[i].value = pastedData[i];
            } else {
                cells.value[i].value = '';
            }
        }
        emit('update:modelValue', pinCodeComputed.value);
    };

    // Helpers
    const isTheCellValid = (cell) => {
        return cell && !!cell.match(CELL_REGEXP);
    };

    const focusCellByIndex = (index = 0) => {
        const el = itemRefs[index];
        if (el) {
            el.focus();
            el.select();
            focusedCellIdx.value = index;
            onValueUpdated();
        }
    };

    onMounted(() => {
        init();
        onValueUpdated();
        if (props.autofocus) {
            nextTick(focusCellByIndex);
        }
    });
</script>

<style scoped>
    .pincode-input-wrapper {
        display: inline-flex;
    }

    .pincode-input {
        font-weight: bold;
        border: 2px solid #dee2e6;
        color: #4a4aa3;
        outline: none;
        margin: 1rem 0.1rem;
        text-align: center;
        border-radius: 6px;
        height: 70px;
        width: 45px;
        font-size: 35px;
    }

        .pincode-input:focus {
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
        }

    .space {
        display: none;
    }

    @media (min-width: 440px) {
        .space {
            display: inline-block;
            user-select: none;
        }

    }

    @media (min-width: 680px) {
        .pincode-input {
            height: 90px;
            width: 70px;
            font-size: 45px;
            margin: 1rem 0.2rem;
        }
    }
</style>

import { reactive, readonly } from "vue";

const state = reactive({
    isAuthenticated: false,
    currentSystemUserId: '',
    currentUser: {},
    customerId: null,
    modules: {}
});

const setAuthenticated = function (isAuthenticated) {
    state.isAuthenticated = isAuthenticated;
};
const setCurrentSystemUserId = function (currentSystemUserId) {
    state.currentSystemUserId = currentSystemUserId;
};
const setCurrentUser = function(currentUser) {
    state.currentUser = currentUser;
};
const setCustomerId = function (id) {
    state.customerId = id;
};
const setModules = function (modules) {
    state.modules = modules;
};

const reset = function() {
    state.isAuthenticated = false;
    state.currentSystemUserId = '';
    state.currentUser = {};
    state.customerId = null;
    state.modules = { };
};

export default { auth: readonly(state), setAuthenticated, setCurrentSystemUserId, setCurrentUser, setCustomerId, setModules, reset };



const apiUrl = process.env.VUE_APP_GRAPHQL_URL;

async function mutation(gqlObject) {

    // Example:
    // { 'query': 'mutation { addDocumentTemplate(input: { name: "' + documentTemplateName.value + '" }) { id }}' }
    let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gqlObject),
        credentials: 'include'
    };

    const response = await window.fetch(apiUrl, requestOptions);
    return response.json();
}

async function query(gqlObject) {
    let requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(gqlObject),
        credentials: 'include'
    };

    const response = await window.fetch(apiUrl, requestOptions);
    return validateResponse(response);
}

async function get(url) {
    let requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
    };

    const response = await window.fetch(url, requestOptions);
    return validateResponse(response);
}

async function redirect(url) {
    window.location = url;    
}

async function patch(url, obj) {
    let requestOptions = {
        method: "PATCH",
        body: JSON.stringify(obj),
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
    };

    const response = await window.fetch(url, requestOptions);
    return validateResponse(response);
}

async function postJson(url, json) {
    let requestOptions = {
        method: "POST",
        body: JSON.stringify(json),
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
    };

    const response = await window.fetch(url, requestOptions);
    return validateResponse(response);
}

// Old post calls
async function postFormData(url, formData) {
    let requestOptions = {
        method: "POST",
        body: formData,
        credentials: 'include'
    };

    const response = await window.fetch(url, requestOptions);

    if (response.ok) {
        if (response.headers.get("content-type")) {
            return response.json();  
        } else {
            return null;  
        }
    } else {
        throw new Error(`Request failed with status ${response.status}`);
    }
}

async function del(url) {
    let requestOptions = {
        method: "DELETE",
        credentials: 'include'
    };

    const response = await window.fetch(url, requestOptions);
    return validateResponse(response);
}

function hasData(response) {
    return response && response.data && !response.errors;
}

function getData(response) {
    if (hasData(response)) {
        return response.data[Object.keys(response.data)];
    }
    return response;
}



function validateResponse(response) {
    if (response.status >= 400) {
        const errorMessage = `HTTP error! status: ${response.status}`;
        throw new Error(errorMessage);
    }

    if (response.headers.has('Content-Type') && response.headers.get('Content-Type').indexOf('application/json') !== -1) {
        return response.text().then(text => JSON.parse(text, dateReviver));
    }

        // TODO: fix endpoint: https://localhost:44345/api/v1/customer/2a532ad8-e190-48f9-a044-41d497c0dd99/document/8b15f87c6b0848a8a442599ce17bd7e5
    else if (response.headers.has('Content-Type') && response.headers.get('Content-Type').indexOf('text/plain') !== -1) {
        return response.json();
    }
    return response;
}

// Custom reviver function to convert date strings to Date objects
function dateReviver(key, value) {
    const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
    if (typeof value === 'string' && dateRegex.test(value)) {
        return new Date(value);
    }
    return value;
}


export default {
    mutation,
    query,
    get,
    patch,
    postJson,
    postFormData,
    del,
    getData,
    hasData,
    redirect
}
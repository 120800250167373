<template>
    <div class="view-consents">
        <div v-if="!vm.view || vm.view === 'overview'">
            <page-header headline="Samtykker">
                <div class="p-input-icon-left mx-2" style="width:100%;flex:1 1 auto;">
                    <i class="pi pi-search" />
                    <InputText style="min-width:300px;width:100%;" v-model="vm.filtersForConsentList['global'].value" placeholder="Søg efter samtykke..." />
                </div>
                <consent-creator @on-saved="(data, isEdit)=>fn.addOrUpdateConsent(data, isEdit)" ref="cc"></consent-creator>
            </page-header>
            <div class="content-wrapper">
                <div class="main-content">
                    <DataTable v-if="!vm.fetchingData && vm.consents && vm.consents.length"
                               ref="dt" stripedRows
                               :value="vm.consents"
                               v-model:selection="vm.selectedTemplates"
                               dataKey="id"
                               sortField="name" :sortOrder="1"
                               :paginator="false" :rows="10" :filters="vm.filtersForConsentList"
                               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                               currentPageReportTemplate="Viser {first} til {last} af {totalRecords} samtykker" responsiveLayout="scroll">
                        <template #empty>
                            Der er desværre ingen samtykker, der matcher din søgning
                        </template>
                        <Column field="name" header="Navn" :sortable="true" style="min-width:260px;">
                            <template #body="slotProps">
                                <div class="p-link clickable" @click="slotProps.data.usage ? fn.goToConsentDetails(slotProps.data.id) : fn.editConsent(slotProps.data)">
                                    <i v-if="slotProps.data.usage" v-tooltip.top="'Samtykket er i brug og kan derfor ikke ændres'" class="pi pi-lock mr-3"></i>
                                    <i v-else class="pi pi-lock-open mr-3"></i>
                                    <strong>{{slotProps.data.name}}</strong>
                                </div>
                            </template>
                        </Column>
                        <Column field="usage" header="Antal" :sortable="true" headerStyle="width:100px" bodyClass="text-center"></Column>
                        <Column field="updated" header="Opdateret" :sortable="true" headerStyle="width:200px">
                            <template #body="slotProps">
                                {{$filters.formatDate(slotProps.data.updated)}}
                            </template>
                        </Column>
                        <Column :exportable="false" class="text-center" style="width:80px">
                            <template #body="slotProps">
                                <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text mr-2 py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggle($event, slotProps.data)" />
                                <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="consentExtraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                            </template>
                        </Column>
                    </DataTable>
                    <Message severity="info" :closable="false" v-else>
                        Du har endnu ikke oprettet nogen samtykker. Klik på "Opret ny samtykke" for at komme i gang
                    </Message>
                </div>
            </div>
        </div>
        <div v-if="vm.view === 'details'">
            <page-header :headline="'Samtykke: ' + (vm.membersWithConsentId && vm.membersWithConsentId.consentName ? vm.membersWithConsentId.consentName : '')" :enableBackButton="true" @backAction="fn.goToOverview()">
                <span class="p-input-icon-left d-print-none">
                    <i class="pi pi-search" />
                    <InputText v-model="vm.filters['global'].value" placeholder="Søg efter kontakt..." />
                </span>
            </page-header>
            <div class="content-wrapper">
                <div class="main-content">
                    <div v-if="!vm.fetchingData && vm.membersWithConsentId && vm.membersWithConsentId.members && vm.membersWithConsentId.members.length">
                        <DataTable ref="dt2" stripedRows
                                   :value="vm.membersWithConsentId.members"
                                   dataKey="id"
                                   v-model:filters="vm.filters" filterDisplay="menu"
                                   sortField="firstname" :sortOrder="1"
                                   :paginator="false" :rows="10" :filters="vm.filters"
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                                   currentPageReportTemplate="Viser {first} til {last} af {totalRecords} brugere" responsiveLayout="scroll">
                            <template #empty>
                                Vi fandt desværre ingen samtykkesvar, der matcher din søgning eller filtrering. Prøv venligst igen med andre kriterier.
                            </template>
                            <Column header="Hold" filterField="memberGroupName" :showFilterMatchModes="false">
                                <template #body="slotProps">
                                    <span :class="{'text-400': !slotProps.data.isActive}">
                                        {{slotProps.data.memberGroupName}}
                                    </span>
                                </template>
                                <template #filter="{filterModel,filterCallback}">
                                    <div class="field-checkbox">
                                        <RadioButton inputId="membergroups-all" name="membergroups" value="" v-model="filterModel.value" @change="filterCallback()" selected />
                                        <label for="membergroups-all">Alle</label>
                                    </div>

                                    <div v-for="(groupName, index) in vm.possibleMemberGroups" :key="index" class="field-checkbox">
                                        <RadioButton :inputId="'membergroupname-' + index" name="membergroups" :value="groupName" v-model="filterModel.value" @change="filterCallback()" />
                                        <label :for="'membergroupname-' + index">{{groupName}}</label>
                                    </div>
                                </template>
                            </Column>
                            <Column field="firstname" header="Fornavn" :sortable="true">
                                <template #body="slotProps">
                                    <strong :class="{'text-400': !slotProps.data.isActive }">{{slotProps.data.firstname}}</strong>
                                </template>
                            </Column>
                            <Column field="lastname" header="Efternavn" :sortable="true">
                                <template #body="slotProps">
                                    <strong :class="{'text-400': !slotProps.data.isActive }">{{slotProps.data.lastname}}</strong>
                                </template>
                            </Column>
                            <Column header="Opdateret" field="consentAnswerDate" :sortable="true">
                                <template #body="slotProps">
                                    <span :class="{'text-400': !slotProps.data.isActive }">
                                        {{$filters.formatDateSimple(slotProps.data.consentAnswerDate)}}
                                    </span>
                                </template>
                            </Column>
                            <Column header="Svar" field="answer" :sortable="false" dataType="boolean" class="column-state">
                                <template #body="slotProps">
                                    <Tag v-if="slotProps.data.answer === null" severity="info" value="IKKE BESVARET"></Tag>
                                    <Tag v-if="slotProps.data.answer" severity="success" value="JA"></Tag>
                                    <Tag v-if="slotProps.data.answer !== null && !slotProps.data.answer" severity="danger" value="NEJ"></Tag>
                                </template>
                                <template #filter="{filterModel,filterCallback}">
                                    <div class="field-checkbox">
                                        <RadioButton inputId="not-answered" name="answer" value="" v-model="filterModel.value" @change="filterCallback()" selected />
                                        <label for="not-answered">Alle</label>
                                    </div>
                                    <div class="field-checkbox">
                                        <RadioButton inputId="yes" name="answer" value="true" v-model="filterModel.value" @change="filterCallback()" />
                                        <label for="yes">Ja</label>
                                    </div>
                                    <div class="field-checkbox mb-0">
                                        <RadioButton inputId="no" name="answer" value="false" v-model="filterModel.value" @change="filterCallback()" />
                                        <label for="no">Nej</label>
                                    </div>
                                </template>
                            </Column>
                            <Column :exportable="false" class="text-center d-print-none" style="width:80px">
                                <template #body="slotProps">
                                    <Button type="button" icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text py-0 h-2rem w-2rem" aria-haspopup="true" :aria-controls="'overlay_menu_' + slotProps.data.id" @click="fn.toggleConsentAnswer($event, slotProps.data)" />
                                    <Menu :id="'overlay_menu_' + slotProps.data.id" class="kebab-slide-out-menu" :model="consentAnswerExtraActions" :popup="false" :class="{'block' : vm.visibleMenuId === slotProps.data.id }" />
                                    <div v-if="vm.visibleMenuId === slotProps.data.id" class="fixed bottom-0 left-0 right-0 top-0" @click="vm.visibleMenuId = null"></div>
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div v-else>
                        <div v-if="vm.fetchingData" class="text-center">
                            <ProgressSpinner />
                        </div>
                        <Message v-else severity="warn">Samtykket er ikke blevet sendt til nogen kontakter endnu</Message>
                    </div>
                </div>
            </div>
        </div>

        <ConfirmDialog group="confirmDeleteConsent" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.currentConsent">Er du sikker på at du vil slette samtykket <b>{{vm.currentConsent.name}}</b>?</div>
            <Message severity="info" :closable="false">Samtykket flyttes til papirkurven, hvor det kan gendannes inden for papirkurvens opbevaringsperiode.</Message>
        </ConfirmDialog>
        <ConfirmDialog group="confirmDeleteConsentAnswer" :style="{'max-width': '600px', width: '100%'}">
            <div v-if="vm.currentConsentAnswer">Er du sikker på at du vil slette samtykkesvaret for <b>{{vm.currentConsentAnswer.firstname}} {{vm.currentConsentAnswer.lastname}}</b>?</div>
            <Message severity="info" :closable="false">Samtykkesvaret flyttes til papirkurven, hvor det kan gendannes inden for papirkurvens opbevaringsperiode.<br />Efterfølgende bliver det arkiveret jf slettereglerne under "indstillinger".</Message>
        </ConfirmDialog>
    </div>
</template>

<script>
    import { inject, ref, reactive } from 'vue';
    import api from './../../composables/api';
    import { FilterMatchMode } from 'primevue/api';
    import gqlBuilder from './../../composables/gql-builder';
    import { useToast } from 'primevue/usetoast';
    import { useConfirm } from "primevue/useconfirm";
    import { useRoute, useRouter, onBeforeRouteUpdate } from 'vue-router';
    import _ from 'lodash';

    export default {
        setup() {
            const store = inject('store');
            const apiUrl = inject('apiUrl');
            const toast = useToast();
            const route = useRoute();
            const router = useRouter();
            const confirm = useConfirm();
            const dt = ref();
            const vm = reactive({
                currentConsentId: null,
                currentConsent: null,
                consents: [],
                membersWithConsentId: null,
                fetchingData: false,
                deleteConsentDialog: false,
                deleteConsentAnswerDialog: false,
                visibleMenuId: null,
                filters: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                    'memberGroupName': { value: '', matchMode: FilterMatchMode.EQUALS },
                    'answer': { value: '', matchMode: FilterMatchMode.EQUALS }
                },
                filtersForConsentList: {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS }
                },
                possibleMemberGroups: []
            });
            const cc = ref();


            const consentAnswerExtraActions = ref([
                {
                    label: 'Træk samtykke tilbage',
                    icon: 'pi pi-times',
                    command: () => {
                        fn.setConsentAnswer(vm.currentConsentAnswer, false);
                        vm.visibleMenuId = null;
                    },
                    visible: () => {
                        if (vm.currentConsentAnswer) {
                            return vm.currentConsentAnswer.answer == undefined || vm.currentConsentAnswer.answer == 1;
                        }
                        return false;
                    },
                },
                //{
                //    label: 'Accepter',
                //    icon: 'pi pi-check',
                //    command: () => {
                //        fn.setConsentAnswer(vm.currentConsentAnswer, true);
                //        vm.visibleMenuId = null;
                //    },
                //    visible: () => {
                //        if (vm.currentConsentAnswer) {
                //            return vm.currentConsentAnswer.answer == undefined || vm.currentConsentAnswer.answer == 0;
                //        }
                //        return false;
                //    },
                //},
                {
                    label: 'Slet',
                    icon: 'pi pi-trash',
                    command: () => {
                        fn.confirmDeleteConsentAnswer();
                    }
                }
            ]);


            const consentExtraActions = ref([
                {
                    label: 'Se svar',
                    icon: 'pi pi-chart-bar',
                    command: () => {
                        fn.goToConsentDetails(vm.currentConsent.id);
                        vm.visibleMenuId = null;
                    },
                    visible: () => {
                        return vm.currentConsent && vm.currentConsent.usage > 0;
                    },
                },
                {
                    label: 'Rediger',
                    icon: 'pi pi-pencil',
                    command: () => {
                        fn.editConsent(vm.currentConsent);
                        vm.visibleMenuId = null;
                    },
                    visible: () => {
                        return vm.currentConsent && vm.currentConsent.usage === 0;
                    },
                },
                {
                    label: 'Slet',
                    icon: 'pi pi-trash',
                    command: () => {
                        fn.confirmDeleteConsent();
                        vm.visibleMenuId = null;
                    }
                }
            ]);

            const fn = {
                addOrUpdateConsent: (consent, isEdit) => {
                    if (!isEdit) {
                        vm.consents.push(consent);
                    } else {
                        const consentIndex = vm.consents.findIndex((obj => obj.id === consent.id));
                        consent.usage = 0;
                        vm.consents[consentIndex] = consent;
                    }
                },
                editConsent: (consent) => {
                    cc.value.fn.edit(consent);
                },
                goToConsentDetails: (id) => {
                    router.push({ name: 'AdminConsents', params: { id: id } });
                },
                confirmDeleteConsent: () => {
                    confirm.require({
                        group: 'confirmDeleteConsent',
                        header: 'Bekræft slet samtykke',
                        acceptLabel: 'Ja, slet',
                        acceptClass: 'p-button-success p-button-lg',
                        rejectLabel: 'Nej, luk',
                        rejectClass: 'p-button-link p-button-lg',
                        accept: () => {
                            fn.deleteConsent();
                        }
                    });
                },
                deleteConsent: () => {
                    api.del(apiUrl + 'consents/' + vm.currentConsent.id).then(() => {
                        vm.deleteConsentDialog = false;
                        const consentIndex = vm.consents.findIndex((obj => obj.id === vm.currentConsent.id));
                        vm.consents.splice(consentIndex, 1);
                        toast.add({ severity: 'success', summary: 'Samtykke slettet', life: 3000 });
                    }).catch((error) => {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                        vm.busy = false;
                    });
                },
                setConsentAnswer: (consentAnswer, answer) => {
                    vm.busy = true;

                    api.patch(apiUrl + 'consentAnswers/' + consentAnswer.consentAnswerId, { answer: answer }).then(() => {
                        consentAnswer.answer = answer;
                        consentAnswer.consentAnswerDate = new Date();


                        if (answer) {
                            toast.add({ severity: 'success', summary: 'Samtykke accepteret', life: 3000 });
                        } else {
                            toast.add({ severity: 'success', summary: 'Samtykke trukket tilbage', life: 3000 });
                        }
                        vm.busy = false;

                    }).catch((error) => {
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });

                        vm.busy = false;
                    });
                },
                confirmDeleteConsentAnswer: () => {
                    confirm.require({
                        group: 'confirmDeleteConsentAnswer',
                        header: 'Bekræft slet samtykkesvar',
                        acceptLabel: 'Ja, slet',
                        acceptClass: 'p-button-success p-button-lg',
                        rejectLabel: 'Nej, luk',
                        rejectClass: 'p-button-link p-button-lg',
                        accept: () => {
                            fn.deleteConsentAnswer();
                        }
                    });
                },
                deleteConsentAnswer: () => {
                    vm.busy = true;
                    api.del(apiUrl + 'consentAnswers/' + vm.currentConsentAnswer.consentAnswerId).then(() => {
                        vm.deleteConsentAnswerDialog = false;
                        const consentIndex = vm.membersWithConsentId.members.findIndex((obj => obj.consentAnswerId === vm.currentConsentAnswer.consentAnswerId));
                        vm.membersWithConsentId.members.splice(consentIndex, 1);
                        toast.add({ severity: 'success', summary: 'Samtykke slettet', life: 3000 });

                        vm.busy = false;
                    }).catch((error) => {

                        vm.deleteConsentAnswerDialog = false;
                        toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                        vm.busy = false;
                    });
                },
                getStats: (id) => {
                    api.query(gqlBuilder.consents.getMembersWithConsentId(store.auth.customerId, id)).then((response) => {
                        if (api.hasData(response)) {
                            vm.membersWithConsentId = api.getData(response);
                            var allMemberGroups = [];
                            _.forEach(vm.membersWithConsentId.members, function (obj) {
                                if (obj.memberGroupName) {
                                    allMemberGroups.push(obj.memberGroupName);
                                }
                            });

                            vm.possibleMemberGroups = _.uniq(allMemberGroups);
                        }
                        vm.fetchingData = false;
                    });
                },
                goToOverview: () => {
                    router.push({
                        name: 'AdminConsents'
                    });
                },
                toggle: (event, data) => {
                    vm.currentConsent = data;
                    vm.visibleMenuId = vm.currentConsent.id === vm.visibleMenuId ? null : vm.currentConsent.id;
                },
                toggleConsentAnswer: (event, data) => {
                    vm.currentConsentAnswer = data;
                    vm.visibleMenuId = vm.currentConsentAnswer.id === vm.visibleMenuId ? null : vm.currentConsentAnswer.id;
                }

            };

            onBeforeRouteUpdate((to) => {
                init(to.params.id);
            });


            const init = (consentId) => {
                vm.currentConsentId = consentId;
                
                if (vm.currentConsentId) {
                    vm.fetchingData = true;
                    fn.getStats(vm.currentConsentId);
                    vm.filters.memberGroupName.value = '';
                    vm.filters.answer.value = '';
                    vm.view = 'details';
                } else {
                    if (vm.consents && vm.consents.length === 0) {
                        vm.fetchingData = true;
                        api.get(apiUrl + 'consents').then((response) => {
                            if (response) {
                                vm.consents = response;
                            }
                            vm.fetchingData = false;
                        }).catch((error) => {
                            toast.add({ severity: 'error', summary: 'Der skete en fejl', detail: error, life: 10000 });
                            vm.fetchingData = false;
                        });

                    }
                    vm.view = 'overview';
                }
            };


            init(route.params.id);

            return {
                vm, fn, dt, cc, consentExtraActions, consentAnswerExtraActions

            }
        }
    }
</script>
<style type="text/sass">
    .column-auto-renewal .pi {
        font-size: 20px;
    }

    .p-column-filter-buttonbar {
        display: none !important;
    }

    .kebab-slide-out-menu {
        position: absolute;
        right: 0;
        z-index: 1;
        display: none;
    }
</style>
import { createRouter, createWebHistory } from 'vue-router'
import startView from '../pages/Start.vue'
import loginView from '../pages/Login.vue'
import documentView from '../pages/Document.vue'
import documentAccessView from '../pages/DocumentAccess.vue'

// ADMIN
import adminHomeView from '../pages/admin/AdminHome.vue'
import adminConsentsView from '../pages/admin/AdminConsents.vue'
import adminMembersView from '../pages/admin/AdminMembers.vue'
import adminTemplatesView from '../pages/admin/AdminTemplates.vue'
import adminTemplateStats from '../pages/admin/AdminTemplateStats'
import adminView from '../pages/admin/AdminMaster.vue'
import adminSettingsView from '../pages/admin/AdminSettings.vue'
import adminLoginView from '../pages/admin/AdminLogin.vue'
import adminLogView from '../pages/admin/AdminLog.vue'
import adminHelpView from '../pages/admin/AdminHelp.vue'
import adminReleasesView from '../pages/admin/AdminReleases.vue'
import adminRecycleBin from '../pages/admin/AdminRecycleBin.vue'
import adminArchive from '../pages/admin/AdminArchive.vue'

// PROFILE
//import profileMasterView from '../pages/profile/ProfileMaster.vue'
//import profileHomeView from '../pages/profile/ProfileHome.vue'
//import profileDocumentsView from '../pages/profile/ProfileDocuments.vue'
//import profileDataView from '../pages/profile/ProfileData.vue'
//import profileConsentsView from '../pages/profile/ProfileConsents.vue'


const routes = [
    //{
    //    path: '/documents/:mid/:did',
    //    name: 'Documents',
    //    component: documentsView,
    //    meta: {
    //        requiresMemberAuth: true
    //    }
    //},
    {
        path: '/',
        name: 'Start',
        component: startView
    },
    {
        path: '/login',
        name: 'Login',
        component: loginView
    },
    {
        path: '/admin',
        name: 'Admin',
        component: adminView,
        children: [
            {
                name: 'AdminLogin',
                path: 'login',
                component: adminLoginView
            },
            {
                name: 'AdminHome',
                path: '',
                component: adminHomeView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminSettings',
                path: 'settings',
                component: adminSettingsView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminLog',
                path: 'log',
                component: adminLogView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminConsents',
                path: 'consents/:id?',
                component: adminConsentsView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminTemplates',
                path: 'templates/:id?',
                component: adminTemplatesView,
                meta: {
                    requiresSystemUserAuth: true
                }, 
            }, 
            {
                name: 'AdminTemplateStats',
                path: 'templates/:id/stats',
                component: adminTemplateStats,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminMembers',
                path: 'members',
                component: adminMembersView,
                meta: {
                    requiresSystemUserAuth: true
                },
                children: [
                    {
                        name: 'AdminMemberId',
                        path: ':id',
                        component: adminMembersView,
                        meta: {
                            requiresSystemUserAuth: true
                        }
                    }
                ]
            },
            {
                name: 'AdminHelp',
                path: 'help',
                component: adminHelpView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminReleases',
                path: 'releases',
                component: adminReleasesView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminRecycleBin',
                path: 'recyclebin',
                component: adminRecycleBin,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            {
                name: 'AdminArchive',
                path: 'archive',
                component: adminArchive,
                meta: {
                    requiresSystemUserAuth: true
                }
            }
        ]
    },
    {
        path: '/document',
        name: 'Document',
        component: documentView,
        children: [
            //Used when document isn't published yet
            {
                name: 'ProfileDocument',
                path: ':did',
                component: documentView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            // Used when preview in templatebuilder
            {
                name: 'ProfileDocumentTemplate',
                path: 'preview/:dtid',
                component: documentView,
                meta: {
                    requiresSystemUserAuth: true
                }
            },
            //TODO: shouldn't be used anymore?
            //{
            //    name: 'ProfileDocumentToken',
            //    path: 't/:token',
            //    component: documentView,
            //    meta: {
            //        requiresMemberAuth: false
            //    }
            //},
            //{
            //    name: 'ProfileDocumentTokenStudentGrant',
            //    path: 's/:studentGrantToken',
            //    component: documentView,
            //    meta: {
            //        requiresMemberAuth: false
            //    }
            //}
        ]
    },
    {
        path: '/documents',
        name: 'Documents',
        component: documentAccessView,
        children: []
    },

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


export default router

import * as gql from 'gql-query-builder'

const documentTemplates = {
    get: (customerId) => {
        return gql.query({
            operation: 'documentTemplatesByCustomerId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'name',
                'state',
                'type',
                'updated'
            ]
        });
    },
    getById: (customerId, documentTemplateId) => {
        return gql.query({
            operation: 'documentTemplateById',
            variables: {
                id: { value: documentTemplateId, type: 'Uuid', required: true },
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'name',
                'type',
                'state',
                'isActive',
                'updated',                
                'emailText',
                'autoRemindAfter',
                'reminderEmailText',
                'postPublishTask',
                'confirmationText',
                'language',
                {
                    gridItems: [
                        'id',
                        'type',
                        'sortOrder',
                        'content',
                        'embedCode',
                        {
                            consents: [
                                'id',
                                'sortOrder'
                            ]
                        },
                        {
                            questions: [
                                'id',
                                'q',
                                'multipleAnswers',
                                'required',
                                'sortOrder',
                                {
                                    as: [
                                        'id',
                                        'text',
                                        'sortOrder'
                                    ]
                                }
                            ]
                        },
                        {
                            inputFields: [
                                'id',
                                'text',
                                'type',
                                'placeholder',
                                'sortOrder',
                                'required'
                            ]
                        },
                        {
                            masterDataConfiguration: [
                                'personalIdEnabled',
                                'personalIdRequired',
                                'nameEnabled',
                                'nameRequired',
                                'addressEnabled',
                                'addressRequired',
                                'emailEnabled',
                                'emailRequired',
                                'phoneEnabled',
                                'phoneRequired'
                            ]
                        },
                        'headline',
                        'description',
                        'tag',
                        'maxFileSize',
                        'fileLimit',
                        'required',
                        'accept',
                        'signatureType',
                        'senderMustSign',
                        'senderSignAction',
                        'confirmMasterData',
                        'whichRecipientsMustSign',
                        'courseStartDate',
                        'courseEndDate',
                        'courseLengthInWeeks',
                        'incomeYear',
                        'schoolYear',
                        {
                            files: [
                                'fileMetadataId',
                                'url',
                                {
                                    file: [
                                        'id',
                                        'name',
                                        'contentType'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        });
    },
    getRecipientMembersByDocumentTemplateId: (customerId, templateId) => {
        return gql.query({
            operation: 'documentRecipientMembersByDocumentTemplateId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                documentTemplate: {
                    value: {
                        id: templateId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }

            },
            fields: [
                'id',
                'documentName',
                'documentTemplateType',
                {
                    documents: [
                        'id',
                        'state',
                        'documentId',
                        'created',
                        'updated',
                        'noOfTimesReminded',
                        'hasSignatureOrderId',
                        {
                            sendSettings: [
                                'sendToMemberOnly',
                                'sendToMemberRelationsOnly'

                            ]
                        },
                        {
                            owner: [
                                'id',
                                'externalId',
                                'firstname',
                                'lastname',
                                'email',
                                'isActive'
                            ]
                        },
                        {
                            signers: [
                                'state',
                                {
                                    member: [
                                        'id',
                                        'firstname',
                                        'lastname',
                                        'email',
                                        'emailHasBounced'
                                    ]
                                }
                            ]
                        },
                        {
                            recipients: [
                                'id',
                                'state',
                                {
                                    member: [
                                        'id',
                                        'firstname',
                                        'lastname',
                                        'email',
                                        'emailHasBounced'

                                    ]
                                }
                            ]
                        }
                    ]
                }

            ]


        });
    },
   
    getStatsForLatestDocumentTemplates: (customerId) => {
        return gql.query({
            operation: 'documentStatistics',
            variables: {
                //amount: { value: amount, type: 'Integer', required: true },
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'name',
                'totalSentTo',
                'totalCompleted',
                'totalDeclined'
            ]
        });
    },
    getMemberDataByDocumentTemplateId: (customerId, templateId) => {
        return gql.query({
            operation: 'memberDataByDocumentTemplateId',
            variables: {
                templateId: { value: templateId, type: 'Uuid', required: true },
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'documentId',
                'dataHasPendingChanges',
                {
                    owner: [
                        'id',
                        'externalId',
                        'firstname',
                        'lastname',
                        'email'
                    ]
                },
                {
                    currentAddressData: [
                        'street',
                        'zip',
                        'city'
                    ]
                },
                {
                    newAddressData: [
                        'street',
                        'zip',
                        'city'
                    ]
                },
                'studentGrantDataId',
                'otherChildrenAmount',
                'otherChildrenNames',
                'communeCode',
                'communeName',
                'yearsInSchool',
                'schoolName',
                'className',
                'familySetup',
                'requiresDocumentation',
                'workedAbroad',
                'hasBeenReviewed',
                {
                    documentAssets: [
                        'id',
                        'title',
                        'contentType',
                        'url',
                        'scanningResult',
                        'scanningState'
                    ]
                },
                {
                    membersToCompare: [
                        'memberRelationId',
                        'memberType',
                        {
                            currentMemberData: [
                                'personalIdEncrypted',
                                'firstname',
                                'lastname',
                                'email'
                            ]
                        },
                        {
                            newMemberData: [
                                'personalIdEncrypted',
                                'firstname',
                                'lastname',
                                'email'
                            ]
                        }
                    ]
                }
            ]
        });
    },
    update: (customerId, documentTemplate) => {
        return gql.mutation({
            operation: 'createOrUpdateDocumentTemplate',
            variables: {
                input: {
                    value: {
                        id: documentTemplate.id,
                        type: documentTemplate.type || 0,
                        name: documentTemplate.name,
                        state: documentTemplate.state || 0,
                        gridItems: documentTemplate.gridItems || [],
                        customer: {
                            id: customerId
                        },                        
                        emailText: documentTemplate.emailText,
                        autoRemindAfter: documentTemplate.autoRemindAfter || 0,
                        reminderEmailText: documentTemplate.reminderEmailText,
                        postPublishTask: documentTemplate.postPublishTask,
                        confirmationText: documentTemplate.confirmationText,
                        language: documentTemplate.language
                    },
                    type: 'UpdateDocumentTemplateInput',
                    required: true
                }
            },
            fields: [
                'id',
                'name',
                'type',
                'state',
                'updated',
                'isActive',
                'postPublishTask'
            ]
        });
    },
  
    updateState: (customerId, documentTemplateId, state) => {
        return gql.mutation({
            operation: 'updateDocumentTemplateState',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                input: {
                    value: {
                        id: documentTemplateId,
                        state: state || 0,
                    },
                    type: 'UpdateDocumentTemplateStateInput',
                    required: true
                }
            },
            fields: [
                'id',
                'name',
                'state',
                'updated'
            ]
        });
    },
    send: (customerId, id, members, sendToMemberRelationsOnly, sendToMemberOnly, documentSettings) => {
        return gql.mutation({
            operation: 'sendDocumentInvite',
            variables: {
                input: {
                    value: {
                        customerId: customerId,
                        documentTemplateId: id,
                        members: members,
                        sendToMemberRelationsOnly: sendToMemberRelationsOnly,
                        sendToMemberOnly: sendToMemberOnly
                    },
                    type: 'InvitesInput',
                    required: true
                },
                documentSettings: {
                    value: {
                        emailText: documentSettings.emailText || '',
                        autoRemindAfter: documentSettings.autoRemindAfter || null,
                        isDefaultDocumentTemplateValues: documentSettings.isDefaultDocumentTemplateValues || false,
                        forceRemindAllSigners: false
                    },
                    type: 'DocumentSettingsInput'
                }
            },
            fields: [
                'id'
            ]
        });
    },
    sendReminders: (customerId, documentTemplateId, documentSettings) => {
        return gql.mutation({
            operation: 'forceRemindDocumentTemplateDocuments',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                input: {
                    value: {
                        id: documentTemplateId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                },
                documentSettings: {
                    value: {
                        reminderEmailText: documentSettings.reminderEmailText || '',
                        isDefaultDocumentTemplateValues: documentSettings.isDefaultDocumentTemplateValues || false,
                        forceRemindAllSigners: documentSettings.forceRemindAllSigners || false
                    },
                    type: 'DocumentSettingsInput'
                }
            },
            fields: [
                'id'
            ]
        });
    },
    preview: (customerId, documentTemplateId) => {
        return gql.query({
            operation: 'documentPreviewByDocumentTemplateId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                documentTemplate: {
                    value: {
                        id: documentTemplateId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }

            },
            fields: [
                'renderFormOutput',
                'renderPreviewOutput',
            ]


        });
    }
};

const documents = {
    delete: (customerId, documentId) => {
        return gql.mutation({
            operation: 'deleteDocument',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                input: {
                    value: {
                        id: documentId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }
            },
            fields: [
                'id'
            ]
        });
    },
    sendReminder: (customerId, documentId, documentSettings) => {
        return gql.mutation({
            operation: 'forceRemindDocument',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                input: {
                    value: {
                        id: documentId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                },
                documentSettings: {
                    value: {
                        reminderEmailText: documentSettings.reminderEmailText || '',
                        isDefaultDocumentTemplateValues: documentSettings.isDefaultDocumentTemplateValues || false,
                        forceRemindAllSigners: documentSettings.forceRemindAllSigners || false
                    },
                    type: 'DocumentSettingsInput'
                }

            },
            fields: [
                'id'
            ]
        });
    },
    reviewedData: (customerId, documentId, reviewed) => {
        return gql.mutation({
            operation: 'studentGrantDataReviewState',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                id: { value: documentId, type: 'Uuid', required: true },
                reviewed: { value: reviewed, type: 'Boolean', required: true }
            },
            fields: [
                'id'
            ]
        });
    }
};

const memberGroups = {
    get: (customerId) => {
        return gql.query({
            operation: 'memberGroupsByCustomerId',
            variables: {
                id: { value: customerId, type: 'Uuid', required: true }

            },
            fields: [
                'id',
                'name',
                'expiresAt',
                {
                    members: [
                        'id',
                        'isActive'
                    ]
                },
                'isActive',
                'type'
            ]
        });
    },
    getWithMembers: (customerId) => {
        return gql.query({
            operation: 'memberGroupsByCustomerId',
            variables: {
                id: { value: customerId, type: 'Uuid', required: true }

            },
            fields: [
                'id',
                'name',
                'type',                
                'isActive', {
                    members: [
                        'id',
                        'externalId',
                        'isActive',
                        'firstname',
                        'lastname',
                        'phone',
                        'email', {
                            ownedDocuments: [
                                {
                                    documentTemplate: [
                                        'id'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        });
    },
    // BEING USED
    getMembers: (customerId, groupId) => {
        return gql.query({
            operation: 'membersInMemberGroupByMemberGroupId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                id: { value: groupId, type: 'Uuid', required: true }

            },
            fields: [
                'id',
                'isActive',
                'firstname',
                'lastname',
                'email',
                'phone',
                'personalIdEncrypted',
                'externalGroupId',
                'externalId',
                'emailHasBounced',
                'emailBounceStatus',
                'emailBounceMessage',
                {
                    memberRelations: [
                        'id',
                        {
                            relatedMember: [
                                'id',
                                'firstname',
                                'lastname',
                                'email',
                                'phone',
                                'personalIdEncrypted',
                                'emailHasBounced',
                                'emailBounceStatus',
                                'emailBounceMessage',
                            ]
                        }
                    ]
                }
            ]
        });
    },
    getMembersWithBouncedEmail: (customerId) => {
        return gql.query({
            operation: 'membersWithBouncedEmail',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'externalId',
                'firstname',
                'lastname',
                'email',
                'emailHasBounced',
                'emailBounceStatus',
                'emailBounceMessage',
                {
                    memberRelations: [
                        {
                            relatedMember: [
                                'id',
                                'firstname',
                                'lastname',
                                'email',
                                'emailHasBounced',
                                'emailBounceStatus',
                                'emailBounceMessage',
                            ]
                        }
                    ]
                }
            ]
        });
    },
    addOrUpdateMember: (customerId, memberGroupMember) => {
        return gql.mutation({
            operation: 'addOrUpdateMember',
            variables: {
                input: {
                    value: {
                        id: memberGroupMember.id || null,
                        isActive: memberGroupMember.isActive || false,
                        customer: { id: customerId },
                        personalIdType: { id: "893563805e1e41e2b895b4e032764801" },
                        firstname: memberGroupMember.firstname,
                        lastname: memberGroupMember.lastname,
                        email: memberGroupMember.email || '',
                        phone: memberGroupMember.phone || '',
                        address: memberGroupMember.address || '',
                        zip: memberGroupMember.zip || '',
                        city: memberGroupMember.city || '',
                        personalIdEncrypted: memberGroupMember.personalIdEncrypted,
                        className: memberGroupMember.className,
                        externalId: memberGroupMember.externalId,
                        externalGroupId: memberGroupMember.externalGroupId,
                        emailBounceMessage: memberGroupMember.emailBounceMessage || null,
                        emailBounceStatus: memberGroupMember.emailBounceStatus || null,
                        emailHasBounced: memberGroupMember.emailHasBounced || false,
                        memberRelations: memberGroupMember.memberRelations || []
                    },
                    type: 'MemberInput',
                    required: true
                }
            },
            fields: [
                'id'
            ]
        });
    },
    addOrUpdateMemberGroup: (customerId, memberGroupId, memberGroupMembersReferenceIds) => {
        return gql.mutation({
            operation: 'addOrUpdateMemberGroupWithReferenceIds',
            variables: {
                input: {
                    value: {
                        id: memberGroupId,
                        memberIds: memberGroupMembersReferenceIds,
                        customerId: customerId
                    },
                    type: 'AlterMembersInMemberGroupInput',
                    required: true
                }
            },
            fields: [
                'id'
            ]
        });
    },
    deleteMember: (customerId, memberId) => {
        return gql.mutation({
            operation: 'deleteMember',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                memberId: { value: memberId, type: 'Uuid', required: true }
            },
            fields: [
                'id'
            ]
        });
    },
    toggleMemberActive: (customerId, memberId) => {
        return gql.mutation({
            operation: 'toggleMemberActive',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                memberId: { value: memberId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'isActive'
            ]
        });
    },
};

const consents = {
    getMembersWithConsentId: (customerId, consentId) => {
        return gql.query({
            operation: 'membersWithConsentId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                consentId: { value: consentId, type: 'Uuid', required: true }
            },
            fields: [
                'consentName',
                {
                    members: [
                        'id',
                        'firstname',
                        'lastname',
                        'isActive',
                        'answer',
                        'memberGroupName',
                        'consentAnswerDate',
                        'consentAnswerId'
                    ]
                }

            ]
        });
    }
};

const batches = {
    get: (customerId) => {
        return gql.query({
            operation: 'batches',
            variables: {
                input: {
                    value: {
                        id: customerId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }
            },
            fields: [
                'id',
                'documentId',
                'documentName',
                'createdDate',
                'totalSentTo',
                'totalDeclined',
                'totalCompleted'
            ]
        });
    },
    getById: (customerId, batchId) => {
        return gql.query({
            operation: 'batch',
            variables: {
                input: {
                    value: {
                        customerId: customerId,
                        referenceId: { id: batchId }
                    },
                    type: 'BatchInput',
                    required: true
                }
            },
            fields: [
                'sendToMemberRelationsOnly',
                'sendToMemberOnly',
                {
                    documents: [
                        'id',
                        'state',
                        'created',
                        {
                            owner: [
                                'id',
                                'firstname',
                                'lastname',
                                'phone',
                                'email'

                            ]
                        },
                        {
                            signers: [
                                'state',
                                {
                                    member: [
                                        'id',
                                        'firstname',
                                        'lastname',
                                        'email'
                                    ]
                                }
                            ]
                        },
                        {
                            recipients: [
                                'id',
                                'state',
                                {
                                    member: [
                                        'id',
                                        'firstname',
                                        'lastname',
                                        'phone',
                                        'email'
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        });
    },
    sendReminders: (customerId, batchId, documentSettings) => {
        return gql.mutation({
            operation: 'forceRemindBatchDocuments',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true },
                input: {
                    value: {
                        id: batchId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                },
                documentSettings: {
                    value: {
                        reminderEmailText: documentSettings.reminderEmailText || '',
                        isDefaultDocumentTemplateValues: documentSettings.isDefaultDocumentTemplateValues || false,
                        forceRemindAllSigners: documentSettings.forceRemindAllSigners || false
                    },
                    type: 'DocumentSettingsInput'
                }
            },
            fields: [
                'id'
            ]
        });
    }
};

const customer = {
    getConfiguration: (customerId) => {
        return gql.query(
            {
                operation: 'configurationByCustomerId',
                variables: {
                    customerId: { value: customerId, type: 'Uuid', required: true }
                },
                fields: [
                    'partnerLogoUrl',
                    'partnerName'
                ]
            }

        );
    },

    get: (customerId) => {
        return gql.query({
            operation: 'customerById',
            variables: {
                referenceId: {
                    value: {
                        id: customerId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }
            },
            fields: [
                'id',
                'name',
                'logoUrl',
                'signatureData',
                'signatureName',
                'street',
                'zip',
                'city',
                'phone',
                'email',
                'vatNo'


            ]
        });
    },
    update: (customerId, name, street, zip, city, phone, email, signatureName) => {
        return gql.mutation({
            operation: 'updateCustomer',
            variables: {
                input: {
                    value: {
                        id: customerId,
                        name: name,
                        signatureName: signatureName,
                        street: street,
                        zip: zip,
                        city: city,
                        phone: phone,
                        email: email
                    },
                    type: 'CustomerInput',
                    required: true
                }
            },
            fields: [
                'id'

            ]
        });
    },
    getDeclinedDocuments: (customerId) => {
        return gql.query({
            operation: 'declinedDocumentsByCustomerId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'state',
                'updated',
                {
                    documentTemplate: [
                        'id',
                        'name'
                    ]
                },
                {
                    owner: [
                        'firstname',
                        'lastname'
                    ]
                }
            ]
        });
    },
    getDeclinedConsents: (customerId) => {
        return gql.query({
            operation: 'declinedConsentsByCustomerId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'consentId',
                'consentAnswerDate',
                'updated',
                {
                    consent: [
                        'name'
                    ]
                },
                {
                    member: [
                        'firstname',
                        'lastname'
                    ]
                }
            ]
        });
    },
    getDocumentsReadyToSenderApproveByCustomerId: (customerId) => {
        return gql.query({
            operation: 'documentsReadyToSenderApproveByCustomerId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
                'state',
                'updated',
                {
                    documentTemplate: [
                        'name'
                    ]
                },
                {
                    owner: [
                        'firstname',
                        'lastname'
                    ]
                }
            ]
        });
    },
    getExpenseLog: (customerId) => {
        return gql.query({
            operation: 'expenseLogByCustomerId',
            variables: {
                customerId: { value: customerId, type: 'Uuid', required: true }
            },
            fields: [
                'id',
            ]
        });
    },    
    getExpenseStats: (customerId) => {
        return gql.query({
            operation: 'expenseStatisticsByCustomerId',
        variables: {
            customerId: { value: customerId, type: 'Uuid', required: true }
        },
            fields: [
                'currency',
                'vatRate',
                {
                    criiptoLoginMitId: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                },
                {
                    criiptoSignature: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                },
                {
                    padSignSignature: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                },
                {
                    approveSignature: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                },
                {
                    criiptoLoginMitId: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                },
                {
                    sms: [                        
                        'price',
                        'quantity',
                        'total'
                    ]
                }         
        ]
    });
    },
}

const systemUsers = {
    get: () => {
        return gql.query({
            operation: 'systemUsers',
            fields: [
                'id',
                'firstname',
                'lastname',
                {
                    customer: [
                        'id',
                        'name'
                    ]
                }
            ]
        });
    },
    getById: (systemUserId) => {
        return gql.query({
            operation: 'systemUserById',
            variables: {
                referenceId: {
                    value: {
                        id: systemUserId
                    },
                    type: 'ReferenceIdInput',
                    required: true
                }
            },
            fields: [
                'id',
                'firstname',
                'lastname',
                {
                    customer: [
                        'id',
                        'name'
                    ]
                }
            ]
        });
    }
}

const auditLog = {
    get: (customerId) => {
        return gql.query({
            operation: 'auditLogEntries',
            variables: {
                input: {
                    value: {
                        customerId: customerId
                    },
                    type: 'AuditLogInput',
                    required: true
                }
            },
            fields: [
                'id',                
                'userName',
                'message',
                'role',
                'action',
                'type',
                'created'
            ]
        });
    }
}

const masterData = {

    getStatusBySignerId: (signerId, token) => {
        return gql.query({
            operation: 'masterDataStatus',
            variables: {
                token: { value: token, type: 'String', required: true },
                signerId: { value: signerId, type: 'Uuid', required: true }

            },
            fields: [
                'signerId',
                'confirmMasterData',
                'masterDataConfirmed',
                {
                    masterData: [
                        'id',
                        'name',
                        'personalIdEncrypted',
                        'phone',
                        'email',
                        'address',
                        'zip',
                        'city',
                        'customerId'

                    ]
                },
            ]
        });
    },
    update: (signerId, token, data) => {
        return gql.mutation({
            operation: 'updateMasterData',
            variables: {
                signerId: { value: signerId, type: 'Uuid', required: true },
                token: { value: token, type: 'String', required: true },
                masterData: {
                    value: data,
                    type: 'MasterDataMemberDtoInput',
                    required: true
                }
            },
            fields: [
                'id'
            ]
        });
    }
}

export default {
    documentTemplates, documents, memberGroups, consents, batches, customer, systemUsers, auditLog, masterData
}
<template>
    <div class="flex justify-content-center">
        <div class="signature-pad">
            <canvas ref="canvas" width="448" height="226" @click="touched = true" @touchstart="touched = true"></canvas>
            <div class="signature-date">Dato: {{formattedDate}}</div>
            <div class="signature-line"><span>{{props.signerName}}</span></div>
        </div>
        <div class="clear-btn">
            <button v-if="props.lang === 'en'" class="btn-clear" @click="clear"><span>Reset</span></button>
            <button v-else-if="props.lang === 'da_en'" class="btn-clear" @click="clear"><span>Nulstil  Reset</span></button>
            <button v-else class="btn-clear" @click="clear"><span>Nulstil</span></button>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, defineExpose, defineProps } from 'vue';
    import SignaturePad from '@/3rdparty/signature_pad';

    const props = defineProps({
        lang: String,
        signerName: String
    });

    const touched = ref(false);
    const canvas = ref(null);
    const signaturePad = ref(null);
    const formattedDate = ref(null);

    const resizeCanvas = (canvasEle) => {
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvasEle.width = canvasEle.offsetWidth * ratio;
        canvasEle.height = canvasEle.offsetHeight * ratio;
        canvasEle.getContext("2d").scale(ratio, ratio);
    };

    const clear = () => {
        touched.value = false;
        signaturePad.value.clear();
    };

    const toDataUrl = () => {
        return signaturePad.value.toDataURL();
    };

    onMounted(() => {
        if (canvas.value) {
            window.onresize = () => resizeCanvas(canvas.value);
            resizeCanvas(canvas.value);

            signaturePad.value = new SignaturePad(canvas.value, {
                backgroundColor: 'rgb(255,255,255)',
                penColor: 'rgb(35, 99, 179)'
            });
        }
        // Date now
        const now = Date.now();
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat('da-DK', options);
        formattedDate.value = formatter.format(now);
    });

    // Expose the touched ref to the parent component
    defineExpose({
        touched,
        clear,
        toDataUrl
    });
</script>

<style>
    .signature-pad {
        border: 2px solid #dce0ec;
        border-right: 0;
        border-radius: 8px 0 0 36px;
        position: relative;
        height: 250px;
        width:100%;
    }

        .signature-pad canvas {
            background: #fff;
            border-radius: 8px 0 0 36px;
            width: 100%;
            height: 100%;
            cursor: crosshair;
        }

        .signature-pad .signature-date {
            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            margin-top: 5px;
            color: #bdbdbd;
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
        }
        
        .signature-pad .signature-line {
            height: 1px;
            border: 1px solid #3f51b5;
            position: absolute;
            bottom: 40px;
            left: 30px;
            right: 20px;
        }

            .signature-pad .signature-line span {
                font-size: 16px;
                font-weight: 600;
                display: inline-block;
                margin-top: 5px;
                color: #bdbdbd;
            }

    .btn-clear {
        height: 100%;
        width: 70px;
        background-color: #3F51B5;
        border: 1px solid transparent;
        border-radius: 0 36px 8px 0;
        color: #fff;
        font-weight: 600;
    }
</style>
